import { getDateNoTime } from '@assets/js/dateUtils';

export const prodTableProperties = [
  {
    label: '采购人员',
    prop: 'prodStffNamelist',
    itemType: 'select',
    options: [],
    input: true,
    sortable: true,
    labelWidth: 160,
    collapseTags: true,
    filterable: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.prod_stff_name
  },
  {
    label: '销售人员',
    prop: 'custStffNamelist',
    itemType: 'select',
    options: [],
    input: true,
    sortable: true,
    labelWidth: 160,
    collapseTags: true,
    filterable: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.scon_stff_name
  },
  {
    label: '客户简称',
    prop: 'cust_abbr',
    otherProp: 'custAbbrList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: true,
    collapseTags: true,
    filterable: true,
    labelWidth: 160,
    multiple: true
  },
  { label: '采购单号', prop: 'podr_no', itemType: 'input', input: true, sortable: false, labelWidth: 120 },
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false, labelWidth: 120 },
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: true, sortable: true, labelWidth: 160 },
  { label: '客户货号', prop: 'prod_cust_no', itemType: 'input', input: true, sortable: true, labelWidth: 120 },
  {
    label: '中文品名',
    prop: 'cn_name',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 160
  },
  { label: '数量', prop: 'control_num', itemType: 'input', input: false, sortable: true, labelWidth: 100, align: 'right' },
  {
    label: '计划交期',
    prop: 'plan_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: true,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '实际交期',
    prop: 'actual_delivery_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: true,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false),
    subItem: { type: 'date', copyAndPaste: true }
  },
  { label: '产品单价', prop: 'product_price', itemType: 'input', input: false, sortable: true, labelWidth: 160, align: 'right' },
  { label: '总金额', prop: 'sum_money', itemType: 'input', input: false, sortable: true, labelWidth: 160, align: 'right' },
  {
    label: '进仓日期',
    prop: 'deli_time',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160,
    subItem: { disabled: true, type: 'popoverInput' }
  },
  {
    label: '最终客户',
    prop: 'custBabbrList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: true,
    collapseTags: true,
    filterable: true,
    labelWidth: 160,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.cust_babbr
  },
  {
    label: '下单日期',
    prop: 'orders_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: true,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false),
    subItem: { disabled: false, type: 'date' }
  },
  {
    label: '供应商简称',
    prop: 'suppAbbrlist',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 160,
    collapseTags: true,
    filterable: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.supp_abbr
  },
  {
    label: '进仓/组合',
    prop: 'group_or_warehouse',
    itemType: 'select',
    options: [
      { value: 0, label: '组合' },
      { value: 1, label: '进仓' }
    ],
    input: true,
    sortable: false,
    labelWidth: 140,
    subItem: {
      disabled: true,
      required: false,
      type: 'newSelect',
      options: {
        0: { value: 0, label: '组合' },
        1: { value: 1, label: '进仓' }
      }
    }
  },
  {
    label: '成品工厂',
    prop: 'suppBabbrlist',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 140,
    collapseTags: true,
    filterable: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.supp_aabbr
  },
  {
    label: '辅料计划交期',
    prop: 'deliveryScheduleForAuxiliary',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140,
    subItem: { disabled: true, type: 'popoverInput' }
  },
  {
    label: '辅料实际交期',
    prop: 'delivery_date',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: false,
    sortable: true,
    labelWidth: 220,
    formatter: val => getDateNoTime(val, false)
    // subItem: { disabled: true, type: 'date' }
  },
  {
    label: '实际出货数量',
    prop: 'actual_shipment_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160,
    subItem: { disabled: false, type: 'input', max: row => row.control_num }
  },
  {
    label: '验货日期',
    prop: 'inspect_time',
    itemType: 'input',
    valueFormat: 'timestamp',
    input: false,
    sortable: true,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false),
    subItem: {
      disabled: false,
      type: 'date',
      copyAndPaste: true,
      change: (val, row) => {
        if (Number(val) > Number(row.plan_time)) {
          row.supp_punctuality = 2;
        } else if (Number(val) <= Number(row.plan_time)) {
          row.supp_punctuality = 1;
        }
      }
    }
  },
  {
    label: '验货员',
    prop: 'inspect_stff_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    subItem: { disabled: false, type: 'newSelect', options: {}, copyAndPaste: true }
  },
  {
    label: '晚交货原因',
    prop: 'lateness_deli_cause',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    subItem: { disabled: false, type: 'popoverInput', copyAndPaste: true }
  },
  {
    label: '准时率',
    prop: 'punctuality',
    itemType: 'select',
    options: [
      { value: 1, label: '准时' },
      { value: 2, label: '不准时' }
    ],
    input: true,
    sortable: false,
    labelWidth: 160,
    subItem: {
      disabled: false,
      type: 'newSelect',
      options: {
        1: { value: 1, label: '准时' },
        2: { value: 2, label: '不准时' }
      },
      copyAndPaste: true
    }
  },
  {
    label: '工厂准时率',
    prop: 'supp_punctuality',
    itemType: 'select',
    input: false,
    options: [
      { value: 1, label: '准时' },
      { value: 2, label: '不准时' }
    ],
    sortable: false,
    labelWidth: 160,
    subItem: {
      disabled: false,
      type: 'newSelect',
      options: {
        1: { value: 1, label: '准时' },
        2: { value: 2, label: '不准时' }
      }
    }
  },
  {
    label: '合格率',
    prop: 'prod_yield',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 160,
    subItem: { disabled: false, type: 'input', copyAndPaste: true }
  },
  {
    label: '质量反馈',
    prop: 'quality_feedback',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160,
    subItem: { disabled: false, type: 'input', copyAndPaste: true }
  },
  {
    label: '工厂配合度',
    prop: 'degree_adaptability',
    itemType: 'select',
    options: [
      { value: 0, label: '配合' },
      { value: 1, label: '不配合' }
    ],
    input: true,
    sortable: false,
    labelWidth: 160,
    subItem: {
      disabled: false,
      type: 'newSelect',
      options: {
        0: { value: 0, label: '配合' },
        1: { value: 1, label: '不配合' }
      }
    }
  },
  {
    label: '不配合内容',
    prop: 'no_coordinate_cause',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160,
    subItem: { disabled: false, type: 'popoverInput', copyAndPaste: true }
  },
  {
    label: '备注',
    prop: 'remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160,
    subItem: { disabled: false, type: 'popoverInput', copyAndPaste: true }
  },
  {
    label: '确认样',
    prop: 'podr_category',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    subItem: {
      disabled: false,
      type: 'select',
      options: [
        { value: '新款', label: '新款' },
        { value: '翻单', label: '翻单' }
      ],
      copyAndPaste: true
    }
  },
  {
    label: '面料',
    prop: 'fabric',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160,
    subItem: { disabled: false, type: 'popoverInput', copyAndPaste: true }
  },
  { label: '长(cm)', prop: 'length', itemType: 'input', input: false, sortable: false, labelWidth: 80 },
  { label: '宽(cm)', prop: 'breadth', itemType: 'input', input: false, sortable: false, labelWidth: 80 },
  { label: '高(cm)', prop: 'height', itemType: 'input', input: false, sortable: false, labelWidth: 80 },
  { label: '箱数', prop: 'box_num', itemType: 'input', input: false, sortable: false, labelWidth: 100 },
  { label: '每箱箱数', prop: 'packing_quantity', itemType: 'input', input: false, sortable: false, labelWidth: 100 },
  { label: '立方数(m³)', prop: 'cubic_number', itemType: 'input', input: false, sortable: false, labelWidth: 100 },
  {
    label: '公司抬头',
    prop: 'cpttNamelist',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 120,
    collapseTags: true,
    filterable: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.cptt_name
  },
  { label: '采购入库数量', prop: 'quantity_in_storage', itemType: 'input', input: false, sortable: false, labelWidth: '200' },
  {
    label: '采购入库状态',
    prop: 'status',
    itemType: 'select',
    input: true,
    sortable: false,
    labelWidth: 105,
    formatter: val => (val === 0 ? '未入库' : val === 1 ? '部分入库' : '已入库'),
    options: [
      { value: 0, label: '未入库' },
      { value: 1, label: '部分入库' },
      { value: 2, label: '已入库' }
    ]
  },
  {
    label: '采购合同状态',
    prop: 'podr_status',
    itemType: 'select',
    input: true,
    sortable: false,
    labelWidth: 105,
    formatter: val => (val === 0 ? '草拟' : val === 1 ? '在批' : '生效'),
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: true, sortable: false, labelWidth: 120 }
];
export const mtrbTableProperties = [
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false, labelWidth: 120 },
  { label: '采购单号', prop: 'modr_no', itemType: 'input', input: true, sortable: false, labelWidth: 120 },
  {
    label: '成品合同号',
    prop: 'podr_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  { label: '客户货号', prop: 'prod_cust_no', itemType: 'input', input: true, sortable: true, labelWidth: 120 },
  {
    label: '中文品名',
    prop: 'cn_name',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 160
  },
  { label: '订单数量', prop: 'control_num', itemType: 'input', input: false, sortable: true, labelWidth: 100, align: 'right' },
  { label: '含备品总数', prop: 'mtrb_num_total', itemType: 'input', input: false, sortable: true, labelWidth: 100, align: 'right' },
  {
    label: '计划交期',
    prop: 'plan_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: true,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '实际交期',
    prop: 'actual_delivery_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: true,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false),
    subItem: {
      type: 'date',
      copyAndPaste: true,
      change: (val, row) => {
        row.actual_shipment_num = row.control_num;
        if (val) row.confirm_shipment = 1;
        else row.confirm_shipment = 0;
      }
    }
  },
  {
    label: '发货批次',
    prop: 'shipment_batch',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160,
    subItem: {
      disabled: false,
      type: 'input',
      max: row => row.shipment_batch,
      maxLength: 200
    }
  },
  {
    label: '产品类型',
    prop: 'prod_type',
    itemType: 'select',
    input: false,
    sortable: false,
    labelWidth: 160,
    subItem: {
      disabled: true,
      required: false,
      type: 'newSelect',
      options: {
        0: { value: 0, label: '新款' },
        1: { value: 1, label: '修改' },
        2: { value: 2, label: '翻单' }
      }
    }
  },
  {
    label: '确认时间',
    prop: 'confirm_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: false,
    sortable: true,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false),
    subItem: { type: 'date' }
  },
  {
    label: '客户简称',
    prop: 'cust_abbr',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 120
  },
  {
    label: '成品交期',
    prop: 'podr_pedate',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: false,
    sortable: true,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '成品工厂',
    prop: 'suppBabbrlist',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 140,
    collapseTags: true,
    filterable: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.supp_aabbr
  },
  { label: '产品单价', prop: 'product_price', itemType: 'input', input: false, sortable: true, labelWidth: 160, align: 'right' },
  { label: '总金额', prop: 'sum_money', itemType: 'input', input: false, sortable: true, labelWidth: 160, align: 'right' },
  {
    label: '采购人员',
    prop: 'prodStffNamelist',
    itemType: 'select',
    options: [],
    input: true,
    sortable: true,
    labelWidth: 160,
    collapseTags: true,
    filterable: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.prod_stff_name
  },
  {
    label: '成品工厂负责人',
    prop: 'modr_stff_name',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140
  },
  {
    label: '销售人员',
    prop: 'custStffNamelist',
    itemType: 'select',
    options: [],
    input: true,
    sortable: true,
    labelWidth: 160,
    collapseTags: true,
    filterable: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.scon_stff_name
  },
  {
    label: '下单日期',
    prop: 'orders_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: true,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false),
    subItem: { disabled: false, type: 'date' }
  },
  {
    label: '供应商简称',
    prop: 'suppAbbrlist',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 160,
    collapseTags: true,
    filterable: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.supp_abbr
  },
  {
    label: '实际出货数量',
    prop: 'actual_shipment_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160,
    subItem: {
      disabled: false,
      type: 'input',
      max: row => row.control_num
    }
  },
  {
    label: '验货员',
    prop: 'inspect_stff_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    subItem: { disabled: false, type: 'newSelect', options: {}, copyAndPaste: true }
  },
  {
    label: '验货日期',
    prop: 'inspect_time',
    itemType: 'input',
    valueFormat: 'timestamp',
    input: false,
    sortable: true,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false),
    subItem: {
      disabled: false,
      type: 'date',
      copyAndPaste: true,
      change: (val, row) => {
        if (Number(val) > Number(row.plan_time)) {
          row.supp_punctuality = 2;
        } else if (Number(val) <= Number(row.plan_time)) {
          row.supp_punctuality = 1;
        }
      }
    }
  },
  {
    label: '晚交货原因',
    prop: 'lateness_deli_cause',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    subItem: { disabled: false, type: 'popoverInput', copyAndPaste: true }
  },
  {
    label: '准时率',
    prop: 'punctuality',
    itemType: 'select',
    options: [
      { value: 1, label: '准时' },
      { value: 2, label: '不准时' }
    ],
    input: true,
    sortable: false,
    labelWidth: 160,
    subItem: {
      disabled: false,
      type: 'newSelect',
      options: {
        1: { value: 1, label: '准时' },
        2: { value: 2, label: '不准时' }
      },
      copyAndPaste: true
    }
  },
  {
    label: '工厂准时率',
    prop: 'supp_punctuality',
    itemType: 'select',
    input: false,
    options: [
      { value: 1, label: '准时' },
      { value: 2, label: '不准时' }
    ],
    sortable: false,
    labelWidth: 160,
    subItem: {
      disabled: false,
      type: 'newSelect',
      options: {
        1: { value: 1, label: '准时' },
        2: { value: 2, label: '不准时' }
      }
    }
  },
  {
    label: '合格率',
    prop: 'prod_yield',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 160,
    subItem: { disabled: false, type: 'input', copyAndPaste: true }
  },
  {
    label: '工厂配合度',
    prop: 'degree_adaptability',
    itemType: 'select',
    options: [
      { value: 0, label: '配合' },
      { value: 1, label: '不配合' }
    ],
    input: true,
    sortable: false,
    labelWidth: 160,
    subItem: {
      disabled: false,
      type: 'newSelect',
      options: {
        0: { value: 0, label: '配合' },
        1: { value: 1, label: '不配合' }
      }
    }
  },
  {
    label: '公司抬头',
    prop: 'cpttNamelist',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 120,
    collapseTags: true,
    filterable: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.cptt_name
  },
  { label: '采购入库数量', prop: 'quantity_in_storage', itemType: 'input', input: false, sortable: false, labelWidth: '200' },
  {
    label: '采购入库状态',
    prop: 'status',
    itemType: 'select',
    options: [
      { value: 0, label: '未入库' },
      { value: 1, label: '部分入库' },
      { value: 2, label: '已入库' }
    ],
    input: true,
    sortable: false,
    labelWidth: 105
  },
  {
    label: '确认发货',
    prop: 'confirm_shipment',
    itemType: 'select',
    options: [
      { value: 0, label: '未发货' },
      { value: 1, label: '已发货' }
    ],
    input: true,
    sortable: false,
    labelWidth: 160
  },
  {
    label: '采购合同状态',
    prop: 'modr_status',
    itemType: 'select',
    input: true,
    sortable: false,
    labelWidth: 105,
    formatter: val => (val === 0 ? '草拟' : val === 1 ? '在批' : '生效'),
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  },
  {
    label: '最终客户',
    prop: 'custBabbrList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: true,
    collapseTags: true,
    filterable: true,
    labelWidth: 160,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.cust_babbr
  },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: true, sortable: false, labelWidth: 120 }
];
export const storageRecordsProperties = [
  {
    prop: 'create_time',
    itemType: 'date',
    label: '操作时间',
    input: true,
    sortable: false,
    valueFormat: 'timestamp',
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  { prop: 'purchase_contract_no', itemType: 'input', label: '采购单号', input: true, sortable: false, widthAuto: true },
  {
    prop: 'selectOptions',
    itemType: 'select',
    options: [],
    label: '操作人',
    input: true,
    multiple: true,
    filterable: true,
    collapseTags: true,
    sortable: false,
    widthAuto: true,
    needOtherColumn: true,
    formatter: row => row.stff_name
  },
  {
    prop: 'batch_type',
    itemType: 'select',
    options: [
      { label: '入库', value: 0 },
      { label: '撤销入库', value: 1 }
    ],
    label: '操作类型',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => (val === 0 ? '入库' : '撤销入库')
  },
  { prop: 'control_num', itemType: 'input', label: '操作数量', input: false, sortable: false, widthAuto: true }
];
export const elDrawerTableProperties = [
  { label: '采购单号', prop: 'modr_no', itemType: 'input', input: true },
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true },
  { label: '客户货号', prop: 'prod_cust_no', itemType: 'input', input: true },
  {
    label: '中文品名',
    prop: 'cn_name',
    itemType: 'input',
    input: true
  },
  { label: '数量', prop: 'control_num', itemType: 'input', input: false, align: 'right' },
  {
    label: '计划交期',
    prop: 'plan_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '实际交期',
    prop: 'actual_delivery_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    formatter: val => getDateNoTime(val, false)
  },
  { label: '产品单价', prop: 'product_price', itemType: 'input', input: false, align: 'right' },
  { label: '总金额', prop: 'sum_money', itemType: 'input', input: false, align: 'right' },
  {
    label: '客户简称',
    prop: 'cust_abbr',
    itemType: 'input',
    input: false
  }
];
