import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const prodControlAPI = {
  getProdControl: params => getNoCatch(`${topic.prodControlTopic}/getProdControls`, params),
  editProdControls: params => postNoCatch(`${topic.prodControlTopic}/editProdControls`, params),
  getProdControlsByid: params => getNoCatch(`${topic.prodControlTopic}/getProdControlsByid`, params),
  getProdControlsExport: params => getNoCatch(`${topic.prodControlTopic}/getProdControlsExport`, params),
  editBatchProdControls: params => postNoCatch(`${topic.prodControlTopic}/editBatchProdControls`, params),
  getProdControlsRecord: params => getNoCatch(`${topic.prodControlTopic}/getProdControlsRecord`, params)
};
