<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="flexV">
        <div style="margin-right: 10px">
          <span style="display: inline-block">采购部门：</span>
          <el-select v-model="podr_dept_name" filterable multiple placeholder="请选择" size="small" clearable style="display: inline-block; width: 200px">
            <el-option v-for="item in prodDeptName" :key="item.supp_id" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
        <span>合同日期：</span>
        <el-date-picker
          size="small"
          class="vg_mr_8"
          end-placeholder="结束日期"
          v-model="dateRange2"
          @change="val => changeDateRange(val, 2)"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          style="width: 300px"
        />
        <span>计划日期：</span>
        <el-date-picker
          size="small"
          class="vg_mr_8"
          end-placeholder="结束日期"
          v-model="dateRange4"
          @change="val => changeDateRange(val, 4)"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          style="width: 300px"
        />
        <div style="margin-left: auto">
          <div style="display: flex">
            <div style="width: 250px">全部页总数量:{{ total_quantit }}</div>
          </div>
        </div>
      </div>
      <div class="flexV">
        <el-button size="small" type="primary" icon="el-icon-search" @click="getProdControlList()" class="vg_mt_8"> 查询 </el-button>
        <el-button type="info" size="small" class="vg_mt_8" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新 </el-button>
        <el-button type="success" size="small" class="vg_mt_8" @click="exportExcel()">导出Excel</el-button>
        <div style="margin-left: auto">
          <div style="display: flex">
            <div style="width: 250px">全部页总金额:{{ total_amount }}</div>
          </div>
        </div>
      </div>
      <div v-if="$refs.multiTable" class="flexV vg_mt_8" style="height: 36px">
        <el-radio-group v-model="radioButton" size="small" @input="changeProdControlType" class="vg_mr_8">
          <el-radio-button size="small" label="成品"></el-radio-button>
          <el-radio-button label="材料" size="small"></el-radio-button>
        </el-radio-group>
        <el-checkbox-group v-model="statusList" style="margin-right: 10px">
          <el-checkbox v-for="item in statusNameList" :label="item.status" :key="item.status" :style="item.style" @change="getProdControlList"
            >{{ item.status_name }}
          </el-checkbox>
        </el-checkbox-group>
        <el-button type="success" size="small" @click="bulkStorage">批量入库</el-button>
        <el-button v-if="['采购一部', 'IT部'].includes($cookies.get('userInfo').dept_name)" type="primary" size="small" @click="copyProdClipboard()"
          >复制</el-button
        >
        <div v-if="radioButton === '材料' && $cookies.get('userInfo').dept_name === '辅料部'" class="vg_ml_8">
          实际交期:
          <el-date-picker
            v-model="filledDate"
            class="vg_ml_8 vg_mr_8"
            placeholder="请选择"
            size="small"
            style="width: 180px"
            type="date"
            value-format="timestamp"
          />
          <el-button plain size="small" type="success" @click="fillTableDate">填充</el-button>
        </div>
        <el-link type="primary" size="small" style="margin-left: auto; font-size: 20px" @click="storageRecordsDialogVisible = true" class="vg_cursor"
          >入库记录
        </el-link>
      </div>
      <DynamicUTable
        ref="multiTable"
        :tableData="tableData"
        :columns="tableProperties"
        :needPageSizes="true"
        :loadFlag="loadFlag"
        :totalPage="totalPage"
        :pageSize="50"
        :need-fixed-height="true"
        :need-search="true"
        :needChangeColumnProperties="true"
        :editActived="editActived"
        :editClosed="editClosed"
        :row-click="row => (currentRow = row)"
        :db-click-jp="dbClick"
        :row-style="setRowStyle"
        :needCheckBox="true"
        :cell-mouse-enter="testCellMouseEnter"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @getTableData="getProdControlList"
        :otherHeaderCellStyle="headerCellStyle"
        :show-summary="true"
        :activeMethod="activeMethod"
        :summariesColumns="['control_num', 'sum_money', 'cubic_number', 'box_num', 'quantity_in_storage']"
      >
        <template v-slot:quantity_in_storage="scope">
          <div class="flexHV" @click="e => e.stopPropagation()">
            <span class="vg_mr_8">{{ scope.row.quantity_in_storage }}</span>
            <el-button type="success" size="mini" v-if="scope.row.status !== 2" @click="confirmReceipt(scope.row, scope.$index)">入库 </el-button>
            <el-button type="danger" size="mini" style="margin-left: 0" v-if="scope.row.quantity_in_storage > 0" @click="revocation(scope.row, scope.$index)"
              >撤销入库
            </el-button>
          </div>
        </template>
        <template v-slot:status="scope">
          <div @click="e => e.stopPropagation()">
            <el-tag type="info" size="small" v-if="scope.row.status === 0">未入库</el-tag>
            <el-tag type="warning" size="small" v-if="scope.row.status === 1">部分入库</el-tag>
            <el-tag type="success" size="small" v-if="scope.row.status === 2">已入库</el-tag>
          </div>
        </template>
        <template v-slot:podr_status="scope">
          <div @click="e => e.stopPropagation()">
            <el-tag type="info" size="small" v-if="scope.row.podr_status === 0">草拟</el-tag>
            <el-tag type="warning" size="small" v-if="scope.row.podr_status === 1">在批</el-tag>
            <el-tag type="success" size="small" v-if="scope.row.podr_status === 2">生效</el-tag>
          </div>
        </template>
        <template v-slot:modr_status="scope">
          <div @click="e => e.stopPropagation()">
            <el-tag v-if="scope.row.modr_status === 0" size="small" type="info">草拟</el-tag>
            <el-tag v-if="scope.row.modr_status === 1" size="small" type="warning">在批</el-tag>
            <el-tag v-if="scope.row.modr_status === 2" size="small" type="success">生效</el-tag>
          </div>
        </template>
        <template v-slot:confirm_shipment="scope">
          <div @click="e => e.stopPropagation()">
            <el-tag type="info" size="small" v-if="scope.row.confirm_shipment === 0">未发货</el-tag>
            <el-tag v-if="scope.row.confirm_shipment === 1" size="small" type="success">已发货</el-tag>
          </div>
        </template>
        <template v-slot:delivery_date="scope">
          <div style="display: flex" @click="e => e.stopPropagation()">
            {{ getDateNoTime(scope.row.delivery_date, false) }}
            <el-button
              style="margin-left: auto"
              v-if="radioButton === '成品' && scope.row.group_or_warehouse === 1"
              size="mini"
              type="primary"
              @click="drawerOpened(scope.row.scon_no, scope.row.supp_aabbr)"
              >查看材料
            </el-button>
          </div>
        </template>
        <template v-slot:podr_no="scope">
          <div style="display: flex" @click="e => e.stopPropagation()">
            {{ scope.row.podr_no }}
            <el-button
              v-if="radioButton === '材料' && scope.row.podr_no"
              size="mini"
              style="margin-left: auto"
              type="primary"
              @click="changeProdControlType('成品', scope.row.podr_no)"
              >查看成品
            </el-button>
          </div>
        </template>
      </DynamicUTable>
    </el-card>
    <el-dialog title="入库记录" top="0" @opened="getStorageRecordsData" :visible.sync="storageRecordsDialogVisible" width="70%">
      操作时间:
      <el-date-picker
        v-model="dialogDateRange"
        class="vg_mb_8 vg_mr_8 topDateRangeSearch"
        end-placeholder="操作结束时间"
        range-separator="至"
        size="small"
        start-placeholder="操作开始时间"
        type="daterange"
        value-format="timestamp"
      />
      <el-button icon="el-icon-search" size="small" type="primary" @click="getStorageRecordsData"> 查询</el-button>
      <el-button
        class="vd_export"
        icon="el-icon-refresh-right"
        size="small"
        type="info"
        @click="
          $refs.storageRecords.resetFields();
          dialogDateRange = [];
          getStorageRecordsData();
        "
      >
        刷新
      </el-button>
      <DynamicUTable
        ref="storageRecords"
        :table-data="storageRecordsTableData"
        :columns="storageRecordsProperties"
        :need-search="true"
        :need-fixed-height="true"
        :need-pagination="false"
        :need-check-box="false"
        @getTableData="getStorageRecordsData"
      >
      </DynamicUTable>
    </el-dialog>
    <el-drawer :modal="false" :show-close="true" :visible.sync="drawer" :withHeader="false" :wrapperClosable="false" direction="btt">
      <div class="flexV">
        <span style="margin-left: 20px; font-size: 20px; color: #72767b">查看{{ radioButton === '成品' ? '材料' : '成品' }}</span>
        <i class="el-icon-close vg_cursor" style="font-size: 20px; margin-left: auto; margin-right: 5px; margin-top: 5px" @click="drawer = false" />
      </div>
      <DynamicUTable
        ref="elDrawerTable"
        v-loading="elDrawerTableLoading"
        :columns="elDrawerTableProperties"
        :max-height="250"
        :need-check-box="false"
        :need-fixed-height="false"
        :need-pagination="false"
        :need-search="false"
        :table-data="elDrawerTableData"
        @getTableData="getStorageRecordsData"
      >
      </DynamicUTable>
    </el-drawer>
  </div>
</template>

<script>
import { prodControlAPI } from '@api/modules/prodControl';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import {
  elDrawerTableProperties,
  mtrbTableProperties,
  prodTableProperties,
  storageRecordsProperties
} from '@/views/SalesManagement/ProdControlManage/prodControl';
import { downloadFile, getStff, setTime } from '@api/public';
import SearchTable from '@/components/table/SearchTableV2.vue';
import UrlEncode from '@assets/js/UrlEncode';
import { getDateNoTime } from '@assets/js/dateUtils';

export default {
  name: 'ProdControlList',
  computed: {
    UrlEncode() {
      return UrlEncode;
    },
    tableProperties() {
      if (this.radioButton === '成品') {
        return this.prodTableProperties;
      } else if (this.radioButton === '材料') {
        return this.mtrbTableProperties;
      }
    }
  },
  components: {
    SearchTable,
    DynamicUTable
  },
  data() {
    return {
      statusNameList: [
        { status: 0, status_name: '未入库', style: 'color: rgb(198, 198, 198)' },
        { status: 1, status_name: '部分入库', style: 'color: rgb(233, 157, 66)' },
        { status: 2, status_name: '已入库', style: 'color: rgb(66, 171, 21)' }
      ],
      statusList: [],
      dateRange1: [],
      dateRange2: [],
      dateRange3: [],
      dateRange4: [],
      prodTableProperties: cloneDeep(prodTableProperties),
      mtrbTableProperties: cloneDeep(mtrbTableProperties),
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      podr_dept_name: null,
      prodDeptName: [],
      multiSelection: [],
      changeColumn: [],
      currentRow: {},
      currentColumn: '',
      radioButton: '成品',
      editActivedRow: {},
      dialogDateRange: [],
      storageRecordsDialogVisible: false,
      storageRecordsTableData: [],
      storageRecordsProperties: storageRecordsProperties,
      total_amount: 0,
      total_quantit: 0,
      drawer: false,
      elDrawerTableData: [],
      elDrawerTableProperties: elDrawerTableProperties,
      elDrawerTableLoading: false,
      filledDate: null
    };
  },
  beforeDestroy() {
    if (JSON.stringify(this.currentRow) === '{}') return;
    this.saveRow(this.currentRow);
  },
  created() {
    this.changeProperties();
  },
  async mounted() {
    if (this.$route.query.flag) {
      this.radioButton = this.$route.query.flag;
      await this.changeType(this.$route.query.flag);
      if (this.$route.query.contractNumber) {
        this.$refs.multiTable.searchForm[
          this.radioButton === '成品' ? (Number(this.$route.query.type) === 1 ? 'prodCustNoList' : 'prodNoList') : 'purchase_contract_no'
        ] = this.$route.query.contractNumber;
      }
      //材料客户货号
      if (this.$route.query.cust_catalog_number) {
        this.$refs.multiTable.searchForm['prodCustNoList'] = this.$route.query.cust_catalog_number;
      }
      //跳转合同日期搜索
      if (this.$route.query.start_purchase_contract_time) {
        this.$refs.multiTable.searchForm.start_purchase_contract_time = this.$route.query.start_purchase_contract_time;
        this.$refs.multiTable.searchForm.end_purchase_contract_time = this.$route.query.end_purchase_contract_time;
        this.dateRange2 = [this.$route.query.start_purchase_contract_time, this.$route.query.end_purchase_contract_time];
      }
    }
    await this.initData();
  },
  methods: {
    getDateNoTime,
    //批量入库
    bulkStorage() {
      if (this.multiSelection.length === 0) return this.$message.warning('请选择一条数据!');
      this.$confirm('确认批量入库?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          prodControlAPI.editBatchProdControls(this.multiSelection).then(data => {
            if (data.code === 0) {
              this.initData();
              return this.$message.success(data.msg);
            }
          });
        })
        .catch(() => {});
    },
    editClosed({ row }) {
      if (JSON.stringify(this.editActivedRow) !== JSON.stringify(row)) this.saveRow(row);
    },
    editActived({ row }) {
      this.editActivedRow = cloneDeep(row);
    },
    async initData() {
      await this.getProdControlList();
    },
    async changeProperties() {
      let QCDeptList = await getStff('QC', 'valueList');
      this.prodTableProperties.forEach(item => {
        if (item.label === '验货员') item.subItem.options = QCDeptList;
        if (item.subItem && item.subItem.copyAndPaste) {
          item.subItem.copyChange = (val, row) => {
            this.saveRow(row);
          };
        }
      });
      this.mtrbTableProperties.forEach(item => {
        if (item.label === '验货员') item.subItem.options = QCDeptList;
        if (item.subItem && item.subItem.copyAndPaste) {
          item.subItem.copyChange = (val, row) => {
            this.saveRow(row);
          };
        }
      });
    },
    changeDateRange(val, index) {
      val = val || [];
      switch (index) {
        case 1:
          let [s1, e1] = val;
          this.$refs.multiTable.searchForm.start_orders_time = s1;
          this.$refs.multiTable.searchForm.end_orders_time = e1;
          return;
        case 2:
          let [s2, e2] = val;
          this.$refs.multiTable.searchForm.start_purchase_contract_time = s2;
          this.$refs.multiTable.searchForm.end_purchase_contract_time = e2;
          return;
        case 3:
          let [s3, e3] = val;
          this.$refs.multiTable.searchForm.start_inspect_time = s3;
          this.$refs.multiTable.searchForm.end_inspect_time = e3;
          return;
        case 4:
          let [s4, e4] = val;
          this.$refs.multiTable.searchForm.start_plan_time = s4;
          this.$refs.multiTable.searchForm.end_plan_time = e4;
          return;
      }
    },
    async getProdControlList() {
      this.loadFlag = true;
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      this.convertList(searchForm);
      searchForm.plan_time = searchForm.plan_time ? searchForm.plan_time / 1000 : null;
      searchForm.prodcontrol_type = this.radioButton === '成品' ? 0 : 1;
      await prodControlAPI.getProdControl(searchForm).then(({ data }) => {
        this.btn = data.btn;
        this.totalPage = data.total;
        this.tableData = data.list;
        // this.tableData.forEach(item => {
        //   for (let key in item) {
        //     item[key + '_isMouseEnter'] = false;
        //   }
        // });
        this.total_amount = data.total_amount;
        this.total_quantit = data.total_quantit;
        this.prodTableProperties.forEach(item => {
          if (item.prop === 'custBabbrList') item.options = data.custBabbrList;
          if (item.prop === 'prodStffNamelist') item.options = data.prodStffNamelist;
          if (item.prop === 'custStffNamelist') item.options = data.custStffNamelist;
          if (item.prop === 'suppAbbrlist') item.options = data.suppAbbrlist;
          if (item.prop === 'suppBabbrlist') item.options = data.suppBabbrlist;
          if (item.prop === 'cpttNamelist') item.options = data.cpttNamelist;
          // if (item.otherProp === 'prodDeptName') item.options = data.prodDeptName;
          if (item.otherProp === 'custAbbrList') item.options = data.custAbbrList;
        });
        this.prodDeptName = data.prodDeptName;
        this.mtrbTableProperties.forEach(item => {
          if (item.prop === 'custBabbrList') item.options = data.custBabbrList;
          if (item.prop === 'prodStffNamelist') item.options = data.prodStffNamelist;
          if (item.prop === 'custStffNamelist') item.options = data.custStffNamelist;
          if (item.prop === 'suppAbbrlist') item.options = data.suppAbbrlist;
          if (item.prop === 'suppBabbrlist') item.options = data.suppBabbrlist;
          if (item.prop === 'cpttNamelist') item.options = data.cpttNamelist;
        });
        setTimeout(() => (this.loadFlag = false), 1000);
      });
    },
    buttonRefresh() {
      this.dateRange1 = [];
      this.dateRange2 = [];
      this.dateRange3 = [];
      this.dateRange4 = [];
      this.statusList = [];
      this.podr_dept_name = null;
      this.$refs.multiTable.resetFields();
      this.getProdControlList();
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    async saveRow(val) {
      if (Array.isArray(val)) {
        prodControlAPI.editProdControls(val).then(() => {
          this.initData();
        });
      } else {
        let obj = val || this.currentRow;
        await prodControlAPI.editProdControls([obj]);
        const { data } = await prodControlAPI.getProdControlsByid({
          prodcontrol_id: obj.prodcontrol_id,
          prodcontrol_type: obj.prodcontrol_type
        });
        const item = this.tableData.find(x => x.prodcontrol_id === data.form.prodcontrol_id);
        item.prodcontrol_id = data.form.prodcontrol_id;
        item.status = data.form.status;
      }
    },
    confirmReceipt(row, index) {
      this.$prompt('请输入入库数量', '确认入库', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[\d.]+$/,
        inputErrorMessage: '请输入正确的数量',
        inputValue: Number(row.control_num) - Number(row.quantity_in_storage)
      }).then(({ value }) => {
        row.batch_type = 0;
        row.record_num = value;
        row.quantity_in_storage += Number(value);
        this.saveRow(row);
      });
    },
    revocation(row, index) {
      this.$prompt('请确认撤销数量', '撤销入库', {
        confirmButtonText: '确定撤销',
        cancelButtonText: '取消',
        inputPattern: /^[\d.]+$/,
        inputErrorMessage: '请输入正确的数量',
        inputValue: Number(row.quantity_in_storage)
      }).then(({ value }) => {
        if (value > Number(row.quantity_in_storage)) return this.$message.error('撤销入库数量不可大于入库数量！');
        row.batch_type = 1;
        row.record_num = value;
        row.quantity_in_storage -= Number(value);
        this.saveRow(row);
      });
    },
    async changeProdControlType(type, val) {
      await this.changeType(type, val);
      await this.getProdControlList();
    },
    async changeType(type, val) {
      this.$refs.multiTable.searchForm.prodcontrol_type = type === '成品' ? 0 : 1;
      if (type === '材料' && val) this.$refs.multiTable.searchForm.scon_no = val;
      if (type === '成品' && val) this.$refs.multiTable.searchForm.podr_no = val;
      this.radioButton = type;
      await this.$refs.multiTable.reloadTable();
    },
    convertList(searchForm) {
      searchForm.custBabbrList = searchForm.custBabbrList?.toString();
      searchForm.prodStffNamelist = searchForm.prodStffNamelist?.toString();
      searchForm.custStffNamelist = searchForm.custStffNamelist?.toString();
      searchForm.suppAbbrlist = searchForm.suppAbbrlist?.toString();
      searchForm.suppBabbrlist = searchForm.suppBabbrlist?.toString();
      searchForm.cpttNamelist = searchForm.cpttNamelist?.toString();
      searchForm.prodDeptName = this.podr_dept_name?.toString();
      searchForm.custAbbrList = searchForm.custAbbrList?.toString();
      searchForm.statusList = this.statusList?.toString();
    },
    exportExcel() {
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      this.convertList(searchForm);
      let requestBody = {
        type: 1,
        ...searchForm,
        prodcontrol_type: this.radioButton === '成品' ? 0 : 1
      };
      prodControlAPI.getProdControlsExport(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: '生产控制表统计', type: '.xlsx' });
      });
    },
    setRowStyle({ row }) {
      if (row.status === 1) {
        return { background: 'rgb(251,231,203)' };
      } else if (row.status === 2) {
        return { background: 'rgb(199,230,165)' };
      }
    },
    getStorageRecordsData() {
      let searchForm = cloneDeep(this.$refs.storageRecords.searchForm);
      searchForm.prodcontrol_type = this.radioButton === '成品' ? 0 : 1;
      searchForm.stff_name = searchForm.selectOptions?.toString();
      searchForm.selectOptions = null;
      setTime(searchForm, 'start_time', 'end_time', this.dialogDateRange || [], false);
      prodControlAPI.getProdControlsRecord(searchForm).then(({ data }) => {
        this.storageRecordsTableData = data.list;
        this.storageRecordsProperties.find(({ prop }) => prop === 'selectOptions').options = data.selectOptions;
      });
    },
    dbClick(row) {
      let temp = this.radioButton === '成品';
      this.jump(`/${temp ? 'p' : 'm'}odr_edit`, {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: temp ? 127 : 128,
            form_id: row.id
          })
        )
      });
    },
    headerCellStyle(column) {
      if (this.radioButton === '成品') {
        if (['实际交期', '验货日期', '验货员', '准时率', '合格率', '工厂配合度'].includes(column.title)) return { color: 'red' };
      } else if (this.radioButton === '材料') {
        if (['实际交期', '发货批次', '确认时间', '验货员', '验货日期', '准时率', '工厂准时率', '工厂配合度'].includes(column.title)) return { color: 'red' };
      } else return {};
    },
    drawerOpened(scon_no, supp_aabbr) {
      this.drawer = true;
      this.elDrawerTableLoading = true;
      let searchForm = {};
      if (scon_no) searchForm.scon_no = scon_no;
      if (supp_aabbr) searchForm.suppBabbrlist = supp_aabbr;
      searchForm.prodcontrol_type = 1;
      searchForm.page_no = 1;
      searchForm.page_size = 1000;
      prodControlAPI.getProdControl(searchForm).then(({ data }) => {
        this.elDrawerTableData = data.list;
        setTimeout(() => (this.elDrawerTableLoading = false), 1000);
      });
    },
    fillTableDate() {
      if (!this.filledDate) return this.$message.warning('请选择日期!');
      if (this.multiSelection.length === 0) return this.$message.warning('请选择数据!');
      this.multiSelection.forEach(item => {
        item.actual_delivery_time = this.filledDate;
      });
      this.saveRow(this.multiSelection);
    },
    testCellMouseEnter(row, column, cell, event) {
      // console.info('🚀 ~ file:ProdControlList.vue time:下午6:20 line:598 -----', event);
      //
      // this.$nextTick(() => {
      //   row[column.property + '_isMouseEnter'] = true;
      // });
    },
    //成品复制到粘贴板
    async copyProdClipboard() {
      if (this.multiSelection.length === 0) return this.$message.warning('请选择数据!');
      const columnsToCopy = [
        'podr_no', // 采购单号
        'scon_no', // 销售合同号
        'prod_cust_no', // 客户货号
        'cn_name', // 中文品名
        'control_num', // 数量
        'plan_time' // 计划交期
      ];
      // 构建表头
      const headers = columnsToCopy
        .map(prop => {
          return this.prodTableProperties.find(item => item.prop === prop)?.label || prop;
        })
        .join('\t');
      let dataAsText = headers + '\n'; // 首先添加表头，末尾添加换行符
      for (const row of this.multiSelection) {
        let rowData = '';
        for (const col of columnsToCopy) {
          let value = row[col];
          if (value || value === 0) {
            if (col === 'plan_time') {
              value = getDateNoTime(value, false);
            }
            rowData += `${value}\t`; // 每个字段后添加制表符分隔
          } else rowData += `\t`;
        }
        dataAsText += rowData.slice(0, -1) + '\n';
      }
      try {
        await navigator.clipboard.writeText(dataAsText.trim());
        this.$message.success('已复制到剪贴板');
      } catch (err) {
        this.$message.error('复制到剪贴板失败，请重试');
        console.error('复制到剪贴板失败:', err);
      }
    },
    activeMethod() {
      let { dept_name, stff_id } = this.$cookies.get('userInfo');
      if (this.radioButton === '成品') return true;
      return (dept_name === '辅料部' && this.radioButton === '材料') || stff_id === 1;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

.el-drawer__wrapper {
  pointer-events: none;

  ::v-deep .el-drawer__body {
    pointer-events: auto;
  }
}
</style>
